<template>
  <div class="message box-height">
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <el-tabs
        v-model="activeName"
        class="tabsStyle"
        @tab-click="getTabName"
        v-if="messageList.length > 0"
      >
        <el-tab-pane name="1">
          <span slot="label"
            >财务消息 <span>( {{ messageList[0].count }} )</span>
            <span
              :class="{ topCircle: messageList[0].count > 0 ? true : false }"
            ></span>
          </span>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label"
            >合同消息<span>( {{ messageList[1].count }} )</span>
            <span
              :class="{ topCircle: messageList[1].count > 0 ? true : false }"
            ></span>
          </span>
        </el-tab-pane>
        <el-tab-pane name="3">
          <span slot="label"
            >喜报消息<span>( {{ messageList[2].count }} )</span>
            <span
              :class="{ topCircle: messageList[2].count > 0 ? true : false }"
            ></span>
          </span>
        </el-tab-pane>
        <el-tab-pane name="4">
          <span slot="label"
            >系统公告<span>( {{ messageList[3].count }} )</span>
            <span
              :class="{ topCircle: messageList[3].count > 0 ? true : false }"
            ></span>
          </span>
        </el-tab-pane>
        <el-tab-pane name="5">
          <span slot="label"
            >其他<span>( {{ messageList[4].count }} )</span>
            <span
              :class="{ topCircle: messageList[4].count > 0 ? true : false }"
            ></span>
          </span>
        </el-tab-pane>
        <div style="padding-bottom: 20px" class="clearfix">
          <el-pagination
            style="
              margin-top: 10px;
              float: none;
              text-align: right;
              margin-right: 15px;
            "
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-table
                :header-cell-style="{
                  'background-color': '#f5f7fa',
                }"
                :data="ListData"
                class="tabBorder"
                v-loading="listLoading"
                ref="handSelectTest_multipleTable"
              >
                <el-table-column
                  prop="content"
                  label="消息内容"
                  align="left"
                  min-width="600px"
                >
                  <template slot-scope="scope">
                    <div style="display: flex; align-items: center">
                      <span
                        v-if="!scope.row.isRead"
                        :class="scope.row.isRead ? 'isRead' : 'noRead'"
                      ></span>
                      <p
                        :title="scope.row.content"
                        style="
                          width: 85%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          cursor: pointer;
                        "
                        @click="handleCheck(scope.row)"
                      >
                        {{ scope.row.content }}
                      </p>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="createdTime"
                  label="时间"
                  min-width="120"
                ></el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </div>
      </el-tabs>
    </div>
    <el-drawer
      :visible.sync="dialogVisible"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="closeDialog"
    >
      <div slot="title" class="drawerTitle">
        <div><i class="drawIcon iconfont icon-pl_xb"></i>{{ title }}</div>
      </div>
      <div class="drawerCon">
        <sysMessage ref="sysMessage" :dataDetail="dataDetail"></sysMessage>
      </div>
    </el-drawer>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="isfinancialTask"
      append-to-body
      :modal="false"
      custom-class="drawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose1"
    >
      <div slot="title" class="drawerTitle">
        <div><i class="drawIcon iconfont icon-pl_xb"></i>{{ tit }}</div>
      </div>
      <!-- 发喜报-->
      <div class="drawerCon">
        <happyNews
          v-if="isfinancialTask"
          ref="saveHappyNews"
          inpath="happyNewsApproval"
          :projectResumeId="projectResumeId"
          @getisApproval="getisApproval"
          @changeSaveLoading="changeSaveLoading"
          @loadList="getPageList"
          @closeThis="handleClose1()"
        ></happyNews>
        <div class="drawerCon_footer fixedFoot" v-if="isapproval">
          <el-button
            type="default"
            plain
            size="mini"
            @click="rejectedHappyNews()"
            >驳回</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="saveInfos"
            style="margin-right: 15px"
            >确认并提交</el-button
          >
        </div>
      </div>
    </el-drawer>
    <!-- 审核 -->
    <el-drawer
      :visible.sync="approvalDrawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleCloseApproval"
      destroy-on-close
    >
      <div slot="title" class="drawerTitle">{{ detailTit }}</div>
      <div class="drawerCon">
        <noComtractApporval
          ref="noComtractApporval"
          v-if="detailTit == '无合同开票申请审批' && approvalDrawerVisible"
          :id="relativeId"
          @close="handleCloseApproval"
        ></noComtractApporval>
        <aothers
          ref="aothers"
          :row="kpiRow"
          v-if="detailTit == '消息详情' && approvalDrawerVisible"
          @close="handleCloseApproval"
        ></aothers>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="isShowDetail"
      append-to-body
      :modal="false"
      custom-class="drawerClass redrawerClass"
      :close-on-click-modal="false"
      direction="ttb"
      :before-close="handleClose"
    >
      <div
        slot="title"
        class="drawerTitle"
        style="display: flex; justify-content: space-between"
      >
        <p><i class="drawIcon iconfont icon-rc_bt"></i>人才</p>
        <div>
          <el-button size="mini" @click="goNewPage(resumeId)">
            新窗口打开
          </el-button>
        </div>
      </div>
      <resumeDetails
        ref="resumeDetails"
        style="height: calc(100vh - 120px); overflow: auto"
        v-if="isShowDetail"
        :resumeDetailId="resumeId"
        :hover="hoverInfo"
        :repeatResume="repeatResume"
      ></resumeDetails>
    </el-drawer>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import {
  getPageList,
  readMessage,
  getUnReadCount,
  getAnnouncementDetail,
  offerGoodnewsApprovalReject, //驳回
} from "../../api/api";
import {
  statisticsMessageReadDuration, //系统更新消息阅读时长
  statisticsMessageRead, //点击统计查看消息
  statisticsResumeNewWindow,
} from "../../api/buryingPoint";
import sysMessage from "../../components/sysMessage";
import happyNews from "../../components/happyNews";
import noComtractApporval from "../../components/tools/noComtractApporval";
import aothers from "../../components/message/others";
import resumeDetails from "../../components/resumeDetail";
export default {
  name: "finance",
  computed: {
    ...mapGetters({
      messageList: "getMessageList",
    }),
  },
  data() {
    return {
      activeName: "1",
      countRead: 0,
      props: ["sendData"], // 用来接收父组件传给子组件的数据
      relativeId: null,
      name: this.$route.name,
      path: this.$route.path,
      index: 0,
      tabIndex: this.$route.query.index,
      ListData: [],
      messageOptions: [],
      listLoading: false,
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      isShow: true,
      isShowStyle: true,
      dialogFormVisible: false,
      dialogVisible: false,
      title: "系统更新",
      dataDetail: {},
      isfinancialTask: false,
      projectResumeId: null,
      isapproval: false,
      tit: "喜报审核",
      approvalDrawerVisible: false,
      detailTit: "",
      kpiRow: {},
      meassgePointData: {
        operatedTime: "",
        entry: "消息中心列表",
        first: true,
        id: "",
        leaveTime: "",
        sendTime: "",
        title: "",
        type: "",
      },
      clickMsgData: {
        clickTime: "",
        entry: "消息中心列表页",
        first: true,
        messageId: "",
        type: "",
      },
      isShowDetail: false,
      resumeId: "",
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
    };
  },
  components: {
    sysMessage,
    happyNews,
    noComtractApporval,
    aothers,
    resumeDetails,
  },
  created() {
    this.getPageList();
    this.getUnReadCount();
  },
  methods: {
    decrement() {
      this.$store.dispatch("decrement");
    },
    // 判断path
    getPath(val) {
      if (val == "1" || val == 0) {
        return "财务消息";
      } else if (val == "2" || val == 1) {
        return "合同消息";
      } else if (val == "3" || val == 2) {
        return "喜报消息";
      } else if (val == "4" || val == 3) {
        return "系统公告";
      } else if (val == "5" || val == 4) {
        return "其他";
      }
    },
    getIndex(val) {
      if (val == 0) {
        this.activeName = "1";
      } else if (val == 1) {
        this.activeName = "2";
      } else if (val == 2) {
        this.activeName = "3";
      } else if (val == 3) {
        this.activeName = "4";
      } else if (val == 4) {
        this.activeName = "5";
      }
    },
    handleClose(done) {
      done();
      this.dialogFormVisible = false;
      this.isShowDetail = false;
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.getPageList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.getPageList();
    },
    getPageList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      this.tabIndex = this.$route.query.index;
      this.getIndex(this.tabIndex);
      const obj = {
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        type: this.getPath(this.activeName),
      };
      getPageList(obj).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.pagination.total = res.result.total;
          this.ListData = res.result.records;
        }
        this.listLoading = false;
      });
    },
    getTabName(tab, event) {
      this.pagination.pageNo = 1;
      this.tabIndex = tab.index;
      this.$route.query.index = this.tabIndex;
      this.getPageList();
    },
    handleCheck(row) {
      //查看
      this.relativeId = row.relativeId;
      if (!row.isRead) {
        readMessage({ id: row.id }).then((res) => {
          if (res.success) {
            this.$store.dispatch(
              "updateMessageList",
              this.messageList.map((item) => {
                if (item.label == row.type) {
                  if (item.count > 0) {
                    // debugger
                    return {
                      ...item,
                      count: item.count - 1,
                    };
                  }
                  return item;
                }
                return item;
              })
            );
            this.$store.dispatch("decrement");
          }
        });
      }
      if (this.activeName == "1") {
        // 财务消息
        if (row.relativeId) {
          if (row.type == "无合同开票消息") {
            this.detailTit = "无合同开票申请审批";
            this.approvalDrawerVisible = true;
          } else {
            this.$router.push({ path: "/financeEnquiries" });
          }
        } else {
          this.$router.push({ path: "/report/Pool" });
        }
      } else if (this.activeName == "2") {
        // 合同消息
        this.$router.push({
          path: "/contracts",
          query: { objectId: row.relativeId },
        });
      } else if (this.activeName == "3") {
        this.projectResumeId = row.relativeId;
        this.isfinancialTask = true;
      } else if (this.activeName == "4") {
        getAnnouncementDetail({ id: row.relativeId }).then((res) => {
          if (res.success) {
            this.title = res.result.type;
            this.dataDetail = res.result;
            if (
              res.result.type == "系统更新" ||
              res.result.type == "系统公告"
            ) {
              //埋点
              this.clickMsgData.first = row.isRead;
              this.meassgePointData.type = res.result.type;
              this.meassgePointData.operatedTime = new Date().Format(
                "yyyy-MM-dd hh:mm:ss"
              );
              this.meassgePointData.sendTime = res.result.createdTime;
              this.meassgePointData.first = !row.isRead;
              this.meassgePointData.title = res.result.title;
              this.meassgePointData.id = res.result.id;
            }
            this.dialogVisible = true;
          }
        });
      } else if (this.activeName == "5") {
        if (row.type == "候选人触发") {
          this.resumeId = row.relativeId;
          this.isShowDetail = true;
        } else {
          this.detailTit = "消息详情";
          this.kpiRow = row;
          this.approvalDrawerVisible = true;
        }
      }
      this.clickMsgData.clickTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      this.clickMsgData.type = row.type;
      this.clickMsgData.messageId = row.id;
      statisticsMessageRead(this.clickMsgData).then();
    },
    // 获取未读
    getUnReadCount() {
      getUnReadCount().then((res) => {
        if (res.success) {
          this.messageOptions = res.result.typeAndCount;
          this.$store.dispatch("updateMessageList", this.messageOptions);
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.dialogVisible = false;
      if (this.title == "系统更新") {
        this.meassgePointData.leaveTime = new Date().Format(
          "yyyy-MM-dd hh:mm:ss"
        );
        statisticsMessageReadDuration(this.meassgePointData).then((res) => {});
      }
      this.getPageList();
    },
    //喜报弹窗关闭
    handleClose1() {
      this.isfinancialTask = false;
      this.getPageList();
    },
    //驳回
    rejectedHappyNews() {
      offerGoodnewsApprovalReject(this.projectResumeId).then((res) => {
        if (res.success) {
          this.$message.success("驳回成功");
          this.isfinancialTask = false;
          this.pagination.pageNo = 1;
          this.getPageList();
        }
      });
    },
    //判断是否审核
    getisApproval(data) {
      if (data == "待审批") {
        this.isapproval = true;
        this.tit = "喜报审核";
      } else {
        this.isapproval = false;
        this.tit = "喜报";
      }
    },
    //合同保存修改
    changeSaveLoading(val) {
    },
    saveInfos() {
      this.$refs.saveHappyNews
        .saveHappyNewsApproval()
        .then(() => {
        })
        .catch(() => {
        });
    },
    handleCloseApproval() {
      this.approvalDrawerVisible = false;
      this.pagination.pageNo = 1;
      this.getPageList();
    },
    //打开新窗口
    goNewPage(id) {
      statisticsResumeNewWindow({ resumeId: id }).then();
      let newpage = this.$router.resolve({
        path: "/resume/detailPage",
        query: { resumeId: id },
      });
      window.open(newpage.href, "_blank");
    },
  },
  watch: {
    "$route.query.index": "getPageList",
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.message {
  padding-bottom: 15px;
}
.topCircle {
  width: 5px;
  height: 5px;
  background: red;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -8px;
}

.noRead {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ff3f3f;
  display: inline-block;
}

.isRead {
  padding-left: 10px;
}

.tabsStyle {
  width: 100%;
  margin: 0 auto;
}
</style>
<style lang="less">
.message .el-tabs__nav-scroll {
  padding: 0 15px;
}
.tabsStyle {
  .el-tabs__header {
    margin-bottom: 0px;
  }
  .el-pagination {
    padding-right: 0;
  }
}
.message .cell button span {
  color: #ff8080;
}
.message .cell button span:hover {
  color: #ff3f3f;
}
.message {
  .el-dialog__body {
    padding: 0;
  }
}
</style>